import Link from "next/link";
import Image from "next/image";
import { formatPhoneNumber, formatRating } from "../../util/formatters";
import { useMaster, useMastersApi } from "../../util/hooks/api";
import { useClearAllValues } from "../../util/hooks/state";
import Error from "../../components/error";
import NextButton from "../../components/nextButton";
import SafeImage from "../../components/safeImage";
import { FetchLoader } from "../../components/loader";
import styles from "../../styles/Master.module.sass";

function Review({ text, starsCount, author, phone }) {
  return (
    <div className={styles.review_card}>
      {text && <p className={styles.review_card__text}>{text}</p>}
      <div className={styles.review_card__info}>
        <span className={styles.review_card__info__rating}>{formatRating(starsCount)}</span>
        <p className={styles.review_card__info__author}>{formatPhoneNumber(phone)}</p>
      </div>
    </div>
  );
}

export default function Master() {
  const master = useMaster();
  const { data, error } = useMastersApi("");
  const { data: reviews } = useMastersApi("reviews/?limit=1");
  useClearAllValues();

  if (error) return <Error>{error.info}</Error>;
  if (!data) return <FetchLoader />;

  const lastReview = reviews && reviews.count > 0 && reviews.results[0];

  return (
    <>
      <div className={styles.card}>
        <div className={styles.card__photo}>
          <SafeImage src={data.photo} fallbackSrc="/profile.svg" alt="master" />
        </div>
        <div className={styles.card__info}>
          <p className={styles.card__info__title}>Специалист</p>
          <p className={styles.card__info__name}>{data.first_name}</p>
          <p className={styles.card__info__name}>{data.last_name}</p>
        </div>
      </div>

      {lastReview && (
        <div className={styles.reviews}>
          <div className={styles.reviews__header}>
            <p className={styles.reviews__header__title}>Последний отзыв:</p>
            <p className={styles.reviews__header__link}>
              <Link href={`/${master}/reviews`}>
                <a>См. все</a>
              </Link>
            </p>
          </div>
          <Review
            text={lastReview.text}
            starsCount={lastReview.rating}
            author={lastReview.client_name}
            phone={lastReview.masked_phone}
          />
        </div>
      )}

      <div className={styles.master_info}>
        <div className={styles.master_info__phone}>
          <Image src="/phone.svg" alt="phone" width={28} height={28} />
          <a href={`tel:${data.username}`} className={styles.master_info__phone__number}>
            {formatPhoneNumber(data.username)}
          </a>
        </div>
        <p className={styles.master_info__address}>
          <Image src="/location.svg" alt="location" width={28} height={28} />
          <span>{data.address}</span>
        </p>
      </div>

      <NextButton href={`/${master}/services`}>Записаться онлайн</NextButton>
    </>
  );
}

Master.headerTitle = "Онлайн-запись";
