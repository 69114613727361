import parsePhoneNumber from "libphonenumber-js";

export function formatPhoneNumber(number) {
  let masked_symbols = (number.match(/x/g) || []).length;
  if (masked_symbols > 0) {
    number = number.replace(/x/g, "0");
  }
  number = parsePhoneNumber(number).formatInternational();
  if (masked_symbols > 0) {
    const numberArr = number.split("");
    for (let i = numberArr.length - 1; i >= 0 && masked_symbols > 0; --i) {
      if (numberArr[i] === "0") {
        masked_symbols -= 1;
        numberArr[i] = "x";
      }
    }
    number = numberArr.join("");
  }
  return number;
}

function titler() {
  const cache = new Map();
  const cases = [2, 0, 1, 1, 1, 2];
  function chooseTitle(value, titles) {
    if (!cache.has(value)) {
      const caseIndex = value % 100 > 4 && value % 100 < 20 ? 2 : cases[Math.min(value % 10, 5)];
      cache.set(value, caseIndex);
    }
    return titles[cache.get(value)];
  }
  return chooseTitle;
}

export const titleFormatter = titler();

export function formatTime(value) {
  const [hs, ms, _] = value.split(":");
  const h = parseInt(hs);
  const m = parseInt(ms);
  let ret = "";
  if (h > 0) {
    ret += `${h} ${titleFormatter(h, ["час", "часа", "часов"])}`;
  }
  if (m > 0) {
    ret += ` ${m} ${titleFormatter(m, ["минута", "минуты", "минут"])}`;
  }
  return ret.trim();
}

export function formatPrice(value, currency) {
  return new Intl.NumberFormat("ru-RU", {
    style: "currency",
    currency: currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(value);
}

export function formatRating(starsCount) {
  const emptyStars = 5 - starsCount;
  return (starsCount ? "★".repeat(starsCount) : "") + (emptyStars ? "☆".repeat(emptyStars) : "");
}
