import useSWR from "swr";
import { useRouter } from "next/router";

export const baseUrl = process.env.NEXT_PUBLIC_BACKEND_API_URI;

export function useApi(key, fetcher = null) {
  return useSWR(key ? `${baseUrl}${key}` : null, fetcher);
}

export function useMaster() {
  const router = useRouter();
  const { master } = router.query;
  return master;
}

export function useMastersApi(suffix) {
  const master = useMaster();
  return useApi(master ? `check-in/${master}/${suffix}` : null);
}
