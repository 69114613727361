import { useEffect } from "react";

export function useClearLocalStorage(keys) {
  useEffect(() => {
    for (const k of keys) {
      localStorage.removeItem(k);
    }
    localStorage.removeItem("services");
    localStorage.removeItem("date");
    localStorage.removeItem("time");
  }, []);
}

export function useClearIntermediateValues() {
  useClearLocalStorage(["services", "date", "time"]);
}

export function useClearAllValues() {
  useClearLocalStorage(["services", "date", "time", "recordInfo"]);
}
