import Link from "next/link";
import { Loader } from "./loader";
import styles from "../styles/NextButton.module.sass";

export default function NextButton({
  children,
  href,
  onClick,
  extraContainerClassName,
  loading = false,
  disabled = false,
  rawLinkOptions = {},
}) {
  if (loading) {
    return <Loader className={[styles.next_button, styles.loader].join(" ")} />;
  }

  const classNames = [styles.next_button];
  if (disabled) {
    classNames.push(styles.next_button__disabled);
  }
  if (extraContainerClassName) {
    classNames.push(extraContainerClassName);
  }
  const className = classNames.join(" ");

  if (href.startsWith("http")) {
    return (
      <a className={className} href={href} {...rawLinkOptions}>
        {children}
      </a>
    );
  }

  return (
    <Link href={href} passHref>
      <div onClick={onClick} className={className}>
        <a>{children}</a>
      </div>
    </Link>
  );
}
