import styles from "../styles/Loader.module.sass";

export function Loader({ className }) {
  const loader = (
    <div className={styles.lds_ring}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );

  if (!className) return loader;
  return <div className={className}>{loader}</div>;
}

export function FetchLoader({ extraClassNames = [] }) {
  extraClassNames = [styles.fetch_loader].concat(extraClassNames);
  return <Loader className={extraClassNames.join(" ")} />;
}
