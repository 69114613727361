import { useState } from "react";

export default function SafeImage({ src, fallbackSrc, ...props }) {
  const [imgSrc, setImgSrc] = useState(src || fallbackSrc);

  const onError = () => {
    setImgSrc(fallbackSrc);
  };

  // eslint-disable-next-line jsx-a11y/alt-text
  return <img src={imgSrc} onError={onError} {...props} />;
}
